@import '../styling/variables';

.footer-bar {
  width: 100%;
  height: $footer-bar-height;

  background-color: $capito-red;
  color: white;

  position: fixed;
  bottom: 0;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  z-index: 10;

  font-size: $font-size-xs;

  margin: 0;
  // padding: 0 0 0.1rem;

  overflow: hidden;

  &.de {
    font-size: 0.9 * $font-size-xs;

    @media (min-width: 350px) {
      font-size: $font-size-xs;
    }
  }

  .content {
    width: 100%;
    height: 100%;

    max-width: $max-content-width;

    display: flex;
    align-items: center;
    justify-content: center;

    a {
      text-decoration: none;
      color: white;
      height: 100%;
    }

    .left {
      margin-left: 0.25rem;

      display: flex;
      align-items: center;

      a {
        margin-left: 0;
      }

      a:not(:first-child):before {
        color: white;
        content: '|';
        margin: 0 0.1rem;
      }

      a:after {
        display: none;
      }

      @media (min-width: 450px) {
        a:after {
          display: inline-block;
        }

        a:not(:first-child) {
          margin-left: 0.5rem;

          &:before {
            content: '';
          }
        }
      }
    }

    .right {
      height: 100%;

      display: flex;
      align-items: center;

      margin-left: auto;
      margin-right: 0.2rem;

      a {
        &:after {
          content: '';
        }

        display: flex;
        align-items: center;
      }

      span {
        display: none;
        @media (min-width: 400px) {
          display: inline-block;
        }
      }

      img {
        margin-left: $footer-bar-height / 3;
        height: 75%;
        width: auto;
      }
    }
  }
}
