@import '../../styling/variables';
// @import "../../styling/capito";

.LanguageSelectorContainer {
  margin-top: 5em;
}

.list {
  padding-left: 0 !important;

  &:not(:first-child) {
    margin-top: 1rem;
  }

  .listItem {
    display: inline-block;

    width: 100%;

    padding: 0;

    margin-left: 0;
    margin-top: 1rem;
  }
}
