@import '../styling/variables';

.listItem {
  background-color: white;
  box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.5);

  padding: $font-size-xs / 2 $font-size-xs;
  border-radius: $font-size-xs / 2;

  display: flex;
  flex-direction: row;
  align-items: center;

  transition: background-color 0.3s ease;

  cursor: pointer;

  &:hover {
    background-color: #eee;
  }

  .textWrapper {
    .title {
      font-weight: bold;
    }

    .subtitle {
      font-size: $font-size-xs;
    }
  }

  .image {
    height: ($font-size-xs + $font-size-medium) * 1.5;
    width: ($font-size-xs + $font-size-medium) * 1.5;

    margin-right: $font-size-xs;

    img {
      margin: 0;

      border-radius: $font-size-xs / 2;

      box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.5);
    }
  }
}
