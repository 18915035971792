@import '../../styling/variables';
@import '../../styling/capito';

.SettingsContainer {
  margin-top: 5em;
}

.list {
  padding-left: 0 !important;

  .listItem {
    display: inline-block;

    width: 100%;

    padding: 0;

    margin-left: 0;
  }
}
