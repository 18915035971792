.svgReference {
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
}

.star-rating {
  margin: 0;
}

.star-rating-text {
  padding-left: 0.4em;
}

.c-star {
  width: 0.8em;
  height: 0.8em;
  padding-right: 0.1em;
  stroke: #555;

  &.active {
    fill: #555;
  }
}
