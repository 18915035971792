@import '../../styling/variables';
@import '../../styling/capito';

hr {
  border-style: solid;
  border-width: 0.025rem;
  border-color: black;
  margin-top: 2 * $capito-heading-margin-top;
  margin-bottom: $capito-heading-margin-bottom;
}

.detailsContainer {
  margin-top: 5em;
}

.authorContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  width: 100%;

  .authorText {
  }

  .authorLogo {
    margin-top: $capito-heading-margin-top;

    img {
      width: auto;
      height: auto;
      max-width: 2 * $info-bar-height;
      max-height: 2 * $info-bar-height;
    }
  }
}
