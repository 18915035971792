@import '../../styling/variables';

.bannerContainer {
  width: 100%;
  height: $notification-bar-height;
  z-index: 1001;
  // position: fixed;
  // bottom: 0;
  // margin: 0;
  // left: 0;
  // right: 0;
  // padding: 0 0;
  position: relative;

  background-color: #efefef;
  font-family: 'Helvetica Neue', helvetica, arial, sans-serif;
  overflow: hidden;
  border-bottom: 1px solid #ccc;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  display: flex;
  justify-content: space-between;
}

.close-button {
  border: none;

  .close-icon-container {
    padding: 0;

    .close-icon {
      display: inline-block;
      height: $font-size-large / 2;
      width: $font-size-large / 2;
      cursor: pointer;
      stroke: #fff;

      .circle {
        opacity: 0.1;
      }

      .progress {
        opacity: 0;
        stroke-dasharray: 0, 120;
        transition: 0.5s cubic-bezier(0.165, 0.775, 0.145, 1.02);
      }
    }

    &:hover {
      .close-icon {
        .progress {
          opacity: 1;
          stroke-dasharray: 25, 120;
        }
      }
    }

    .close-icon {
      &:hover {
        .progress {
          opacity: 1;
          stroke-dasharray: 120, 120;
        }
      }
      &:active,
      &.clicked {
        .progress {
          stroke-dasharray: 180, 120;
        }
      }
      &.clicked {
        .progress {
          opacity: 1;
        }
      }
    }
  }
}
