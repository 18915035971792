@import '../../styling/variables';

.contentWrapper {
  display: flex;
  flex-direction: column;
  background-color: #f04;
}

.qrCode {
  display: flex;

  align-items: center;
  justify-content: center;

  flex-direction: column;

  img {
    max-width: 66%;
    height: auto;
  }
}

.copyLink {
  div {
    margin-top: $font-size-xs;
    margin-bottom: $font-size-xs / 2;

    &.copied {
      background-color: lightgreen;
    }
  }
}

.i18nLink {
  margin: 0 0.3em 0 0.3em;
}

.appContentWrapper {
  padding-left: $font-size-large;
  padding-right: $font-size-large;
  min-width: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
}
