@import '../styling/variables';

.tool-bar {
  width: 100%;

  position: fixed;
  bottom: $footer-bar-height;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: white;

  padding: 0;
  margin: 0;

  box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.5);

  z-index: 5;

  $color-icon-available: #000000;
  $color-icon-unavailable: #aaaaaa;

  align-items: center;

  .tool-bar-item-container {
    max-width: $breakpoint-tablet;
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    button {
      flex: 1 1 0;

      margin: 0;
      padding: 1rem;
      display: flex;

      flex-direction: column;

      align-items: center;
      justify-content: center;

      transition: background-color 0.3s ease;

      border: none;
      background-color: #ffffff;

      margin-right: 1px;
      margin-left: 1px;

      font-size: $font-size-medium;
      font-weight: 600;

      cursor: pointer;

      &.unavailable {
        color: $color-icon-unavailable !important;
        cursor: not-allowed;

        &:hover {
          background-color: inherit;
        }
      }

      &:not(:hover) {
        color: $color-icon-available !important;
      }

      i {
        font-size: 0.9 * $font-size-largest;
      }

      div {
        font-size: $font-size-small;
        margin-top: 0.5rem;
        text-align: center;
        display: none;
        @media (min-width: $breakpoint-mobile) {
          display: block;
        }
      }
    }
  }
}
