@import '../../styling/variables';

.info-bar {
  $actual-info-bar-height: $info-bar-height - 1 * $info-bar-branding-height;
  $max-height: 0.8 * $actual-info-bar-height;

  display: flex;

  flex-direction: column;
  align-items: center;

  z-index: 100;

  width: 100%;
  overflow: visible;
  background-color: white;

  box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.5);

  border-color: transparent;
  border-style: solid;

  border-left-width: 0;
  border-right-width: 0;

  border-bottom-width: $info-bar-branding-height;
  border-top-width: 0;

  $margin-horizontal: 0.25 * $actual-info-bar-height;
  $margin-vertical: 0.1 * $actual-info-bar-height;

  .info-bar-main {
    width: 100%;
    max-width: $max-content-width;
    height: $actual-info-bar-height;

    .info-bar-main-content {
      display: flex;
      align-items: center;

      margin: $margin-vertical $margin-horizontal * 2;

      .info-bar-logo {
        padding-bottom: $max-height * 0.1;
        height: $max-height * 0.9;
        max-width: $max-height * 2;
        width: auto;

        @media (min-width: $breakpoint-tablet) {
          padding-top: $max-height * 0.05;
          padding-bottom: $max-height * 0.05;
        }

        margin-right: $margin-horizontal;
      }

      .info-bar-main-text {
        display: flex;
        align-items: baseline;
        justify-content: flex-start;

        min-width: 0;
        margin-right: $actual-info-bar-height;

        .info-bar-title {
          font-size: $font-size-medium;
          margin: 0 !important;
          padding: 0 0.5em;

          @media (min-width: 420px) {
            font-size: $font-size-large;
          }

          @media (min-width: $breakpoint-tablet) {
            font-size: $font-size-largest;
          }

          font-weight: bold;
          min-width: 0;
          margin-right: $actual-info-bar-height;
        }

        .info-bar-by {
          display: none;
          margin: 0 !important;
          font-size: $font-size-large;
          font-weight: 500;
          @media (min-width: $breakpoint-mobile) {
            display: inline-block;
          }
        }

        .info-bar-author {
          margin-left: 0.12em;
          font-size: $font-size-large;
          font-weight: 500;
          display: none;
          @media (min-width: $breakpoint-mobile) {
            display: inline-block;
          }
        }
      }
    }
  }

  .info-bar-details {
    width: 100%;

    @media (min-width: $breakpoint-mobile) {
      display: none;
    }

    .info-bar-details-toggler {
      position: fixed;
      top: 0;
      right: 0;

      margin: 0;

      opacity: 0;

      z-index: 10;

      width: $actual-info-bar-height;
      height: $actual-info-bar-height;

      &:checked {
        ~ .info-bar-details-content {
          max-height: $max-height + 4 * $margin-vertical;
          opacity: 1;
        }

        ~ .info-bar-details-toggler-label {
          .info-bar-details-toggler-icon {
            background: transparent;

            &:before {
              transform: rotate(45deg);
            }

            &:after {
              transform: rotate(-45deg);
            }
          }

          &:not(.steps) {
            .info-bar-details-toggler-icon {
              &:before {
                top: 1px;
                left: 1px;
              }

              &:after {
                top: -1px;
                left: 1px;
              }
            }
          }
        }
      }
    }

    .info-bar-details-toggler-label {
      @media (min-width: $breakpoint-mobile) {
        display: none;
      }

      cursor: pointer;
      display: flex;
      position: absolute;

      align-items: center;
      justify-content: center;

      width: $actual-info-bar-height;
      height: $actual-info-bar-height;

      right: 0;
      top: 0;

      .info-bar-details-toggler-icon {
        $icon-color: black;
        $height: 2px;
        $width: 20px;
        $offset: 7px;

        background: $icon-color;
        display: block;
        height: $height;
        position: relative;
        transition: all 0.2s ease;
        width: $width;

        &:after,
        &:before {
          content: '';
          background: $icon-color;
          display: block;
          height: $height;
          position: relative;
          width: 100%;
          transition: all 0.2s ease;
        }

        &:before {
          top: -$offset;
        }

        &:after {
          top: $offset - $height;
        }
      }
    }

    .info-bar-details-content {
      overflow: hidden;

      display: flex;
      align-items: baseline;

      padding-left: $margin-horizontal;
      padding-right: $margin-horizontal;

      max-height: 0;

      opacity: 0;

      transition: all 0.4s ease-in-out;

      > * {
        margin-top: $margin-vertical;
        margin-bottom: $margin-vertical;
      }

      .info-bar-easy-reading {
        img {
          height: 0.75 * $max-height;
        }
      }
    }
  }

  .info-bar-by {
    visibility: visible;

    font-size: $font-size-largest;
    margin-left: $margin-horizontal;
    margin-right: 2px;
    font-weight: 400;
  }

  .info-bar-author {
    visibility: visible;
    font-size: $font-size-largest;
    font-weight: 400;
  }
}
