@import '../../styling/variables';

.loadingWrapper {
  position: fixed;
  top: 0;
  bottom: 0;

  background-color: white;
  width: 100%;
  padding-top: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .loadingText {
    font-size: $font-size-largest;
    margin-top: $font-size-largest;
  }
}
