@import '../styling/variables';

.modalOverlay {
  z-index: 1000;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  height: 100vh;
  width: 100vw;
  max-width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.5);
}

.modalContent {
  width: 60vw;
  min-width: 12rem;
  max-width: $max-content-width / 3;

  background-color: white;

  padding: $info-bar-height * 3/5 $info-bar-height * 4/5;
  border-radius: $info-bar-height / 2;

  .modalHeader {
    font-size: $font-size-large;
    font-weight: bold;

    margin-bottom: 0.3em;
  }

  .modalInnerContent {
    font-size: $font-size-medium;

    margin-top: 0.5em;

    position: relative;
    display: flex;
    flex-direction: column;
  }

  .modalFooter {
    margin-top: 0.2em;
  }

  button {
    width: 100%;
    border: 1px solid #000;
    border-radius: 0.4em;
    background-color: white;
    padding: 0.4em 0.4em;
    font-size: $font-size-medium;
  }
}
