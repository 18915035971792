@import '../../styling/variables';

.smartbanner {
  max-width: 90%;
  display: block;
  width: 100%;
  height: 100%;

  .smartbanner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: $font-size-xs;
    height: 100%;

    .smartbanner-logo {
      height: $info-bar-height - $font-size-xs/2;
      width: $info-bar-height - $font-size-xs/2;

      img {
        height: $info-bar-height - $font-size-xs/2;
        width: $info-bar-height - $font-size-xs/2;
      }
    }
    .smartbanner-logo-info-container {
      display: flex;
      align-items: center;
      min-width: 0;
      padding-right: $font-size-xs;
    }
    .smartbanner-info {
      display: block;
      padding-left: 0.8em;
      font-size: $font-size-xs;
      line-height: $font-size-xs * 1.2;
      color: #555;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .smartbanner-title {
      color: $capito-red;
      font-weight: 500;
      text-overflow: ellipsis;
      overflow-y: hidden;
      white-space: nowrap;
      font-size: $font-size-small;
      line-height: $font-size-small * 1.2;
    }
    .smartbanner-subtitle {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .smartbanner-button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80%;

      .smartbanner-button {
        padding: $font-size-xs/6 $font-size-xs/3 $font-size-xs/3;
        font-size: $font-size-medium;
        text-align: center;
        font-weight: bold;
        color: #fff;
        background-color: $capito-red;
        text-decoration: none;
        border-radius: 0.4em;
        border-color: $capito-red;

        .smartbanner-button-text {
          text-align: center;
          padding-left: $font-size-xs/3;
          padding-right: $font-size-xs/3;
        }
      }
    }
  }
}
