$capito-base-font-size: 1rem;
$capito-base-margin: 0.5 * $capito-base-font-size;
$capito-base-line-height: 1.5 * $capito-base-font-size;

$capito-heading-font-family: "Roboto", Arial, Helvetica, sans-serif;
$capito-heading-line-height: 0.75 * $capito-base-line-height;
$capito-heading-margin-top: 1.5 * $capito-base-font-size;
$capito-heading-margin-bottom: 0.5 * $capito-base-font-size;

$capito-base-width: 45rem;
$capito-width-a1-factor: 0.25;
$capito-width-a2-factor: 0.3;
$capito-width-b1-factor: 0.75;
$capito-width-b2-factor: 1;
$capito-width-sign-language-factor: 1;
$capito-width-original-content-factor: 1;

$capito-image-margin: 1.5 * $capito-base-font-size;

@import url(https://api-capito.acdn.at/content/css);
