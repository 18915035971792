@import '../styling/variables';

.language-level-bar {
  $margin-vertical: 0;

  $color-icon-available: #000000;
  $color-icon-unavailable: #aaaaaa;

  box-shadow: 0 0.1em 2px -2px #000;

  background-color: white;

  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  padding-top: $margin-vertical;

  z-index: 5;

  .tabs {
    width: 100%;

    max-width: $breakpoint-tablet;

    display: flex;
    align-items: center;

    .cai {
      font-size: $font-size-largest;
    }

    > .language-level-item {
      flex: 1 1 0;

      padding-top: $margin-vertical;
      padding-bottom: $margin-vertical;
      margin-right: 1px;
      margin-left: 1px;
      margin-top: 4px;

      display: flex;
      flex-direction: column;

      align-items: center;
      justify-content: center;

      border: none;
      background-color: #ffffff;
      border-bottom: 3px solid;

      font-size: $font-size-small;
      font-weight: 600;

      transition: background-color 0.3s ease;

      cursor: pointer;

      &:not(.selected):not(:hover):not(.unavailable) {
        border-bottom-color: #fff !important;
        color: $color-icon-available !important;
      }

      &.unavailable {
        color: $color-icon-unavailable !important;
        border-bottom-color: #fff !important;
        cursor: not-allowed;

        &:hover {
          background-color: inherit;
          border-bottom-color: #fff;
        }
      }

      .icon {
        width: 0.9 * $font-size-largest;
        height: 0.9 * $font-size-largest;

        background-color: $color-icon-available;

        mask-size: cover;

        &.a1 {
          mask-image: url('../assets/language-level/a1.svg');
        }

        &.no-a1 {
          mask-image: url('../assets/language-level/a1-na.svg');
          background-color: $color-icon-unavailable;
        }

        &.a2 {
          mask-image: url('../assets/language-level/a2.svg');
        }

        &.no-a2 {
          mask-image: url('../assets/language-level/a2-na.svg');
          background-color: $color-icon-unavailable;
        }

        &.b1 {
          mask-image: url('../assets/language-level/b1.svg');
        }

        &.no-b1 {
          mask-image: url('../assets/language-level/b1-na.svg');
          background-color: $color-icon-unavailable;
        }

        &.original {
          mask-image: url('../assets/language-level/original.svg');
        }

        &.no-original {
          mask-image: url('../assets/language-level/original-na.svg');
          background-color: $color-icon-unavailable;
        }
      }

      img {
        height: 0.9 * $font-size-largest;
      }

      div {
        margin-top: 0.2rem;
      }
    }
  }
}
