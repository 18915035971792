@import 'variables';
@import './capito-icons';
@import './fontawesome/fontawesome.scss';
@import './fontawesome/light.scss';
@import './../../node_modules/react-h5-audio-player/src/styles.scss';
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@200;300;400;500;600;700;800&display=swap');

button,
.capito-app-content,
.capito-app-content h1,
.capito-app-content h2,
.capito-app-content h3,
.capito-app-content h4,
.capito-app-content h5,
.capito-app-content h6 {
  font-family: 'Lexend' !important;
}

body {
  margin: 0;
  font-size: 1rem;
  font-family: 'Lexend' !important;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

@media (min-width: $breakpoint-mobile) {
  .hidden-on-mobile {
    display: none;
  }
}

.no-break {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

main {
  width: 100%;
  display: flex;
  justify-content: center;

  > article,
  > div {
    max-width: $breakpoint-mobile;
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    display: inline-block;
    /* These are technically the same, but use both */
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    word-break: normal !important;
    line-break: strict !important;
    hyphens: none;
    -webkit-hyphens: none;
    -moz-hyphens: none;
  }

  .box {
    &.info {
      .content {
        .group {
          p {
            margin: 0;
            max-width: 100%;

            a {
              &.link {
                &:before {
                  display: none;
                }
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}

.button {
  text-align: center;

  font-size: 1rem;

  box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.5);

  padding: $font-size-xs / 2 $font-size-xs;
  border-radius: $font-size-xs / 2;

  transition: background-color 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: #eee;
  }

  i {
    margin-left: $font-size-xs;
  }
}

a {
  text-decoration: underline;
  color: blue;
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
  word-break: break-all;

  &:visited {
    text-decoration: underline;
    color: blue;
  }
}
