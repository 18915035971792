@import '../../styling/variables';

.smart-banner-height-fix {
  position: relative;
  width: 100vw;
  height: $notification-bar-height;
  display: block;
  min-height: 2em;
  min-width: 2em;
}

.top-bar-wrapper {
  $actual-app-bar-height: $info-bar-height - 1 * $info-bar-branding-height;
  $max-height: 0.8 * $actual-app-bar-height;

  top: 0;
  width: 100%;
  position: fixed;
  overflow: visible;

  background-color: white;

  display: flex;

  flex-direction: column;

  z-index: 100;

  border-color: transparent;
  border-style: solid;

  border-left-width: 0;
  border-right-width: 0;

  border-bottom-width: $info-bar-branding-height;
  border-top-width: 0;

  $margin-horizontal: 0.25 * $actual-app-bar-height;
  $margin-vertical: 0.1 * $actual-app-bar-height;
}
