$fa-font-path: './../../assets/webfonts';
$ca-font-path: './../assets/webfonts';

$info-bar-height: 3rem;
$info-bar-branding-height: 0.2rem;

$footer-bar-height: 1.25rem;
$notification-bar-height: 3.4rem;

$font-size-largest: 1.5rem;
$font-size-large: 1.2rem;
$font-size-medium: 1rem;
$font-size-small: 0.9rem;
$font-size-xs: 0.7rem;

$breakpoint-mobile: 700px;
$breakpoint-tablet: 850px;

$max-content-width: 1.5 * $breakpoint-mobile;

$capito-red: #761136;

$loading-indicator-color: $capito-red;
$loading-indicator-size: 4rem;

$rhap_theme-color: $capito-red !default;
