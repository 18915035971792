@import '../../styling/variables';

.capito-logo-container {
  $actual-app-bar-height: $info-bar-height - 1 * $info-bar-branding-height;
  $max-height: 0.7 * $actual-app-bar-height;
  $margin-horizontal: 0.25 * $actual-app-bar-height;
  $margin-vertical: 0.04 * $actual-app-bar-height;

  margin-left: auto;

  .easy-reading-text {
    color: $capito-red;
    font-size: $font-size-small;
    font-weight: 600;
    max-width: 7em;
    //background-color: #f00;
    padding-left: $font-size-small * 0.75;
  }

  img {
    height: $max-height;
    width: auto;
    padding-top: $margin-vertical;

    @media (min-width: $breakpoint-mobile) {
      margin-left: $margin-horizontal;
    }
  }

  @media (min-width: $breakpoint-mobile) {
    display: flex;
  }

  div {
    display: none;
    @media (min-width: $breakpoint-mobile) {
      display: flex;
    }
  }
}
