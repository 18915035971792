@import '../../styling/variables';

.app-bar {
  $actual-app-bar-height: $info-bar-height - 1 * $info-bar-branding-height;
  $max-height: 0.8 * $actual-app-bar-height;

  width: 100%;
  overflow: visible;

  background-color: white;

  display: flex;

  flex-direction: column;
  align-items: center;

  z-index: 100;

  box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.5);

  border-color: transparent;
  border-style: solid;

  border-left-width: 0;
  border-right-width: 0;

  border-bottom-width: $info-bar-branding-height;
  border-top-width: 0;

  $margin-horizontal: 0.25 * $actual-app-bar-height;
  $margin-vertical: 0.1 * $actual-app-bar-height;

  .app-bar-main {
    width: 100%;
    max-width: $max-content-width;
    height: $actual-app-bar-height;

    .app-bar-main-content {
      display: flex;
      align-items: center;

      margin: $margin-vertical $margin-horizontal * 2;

      .back-button-wrapper {
        transition: background-color 0.3s ease;

        cursor: pointer;

        border-radius: $actual-app-bar-height;
        margin-right: $margin-horizontal * 1.4;

        @media (min-width: $breakpoint-tablet) {
          padding-top: $margin-vertical;
          padding-bottom: $margin-vertical;
          padding-left: $margin-horizontal;
          padding-right: $margin-horizontal;
          margin-left: -$margin-horizontal;
          margin-right: $margin-horizontal;
        }

        &:hover {
          background-color: #eee;
        }

        button {
          padding-right: 0.24em;
          padding-left: 0.24em;

          background: none;
          border: none;
          font-size: $font-size-largest;
          font-weight: bold;
        }
      }

      .app-bar-main-text {
        .app-bar-title {
          display: flex;
          align-items: baseline;
          justify-content: flex-start;

          margin: 0 !important;

          font-size: $font-size-medium;

          @media (min-width: 420px) {
            font-size: ($font-size-medium + $font-size-largest)/2;
          }

          @media (min-width: $breakpoint-tablet) {
            font-size: $font-size-largest;
          }

          font-weight: bold;

          min-width: 0;
          margin-right: $actual-app-bar-height;
        }
      }
    }
  }
}
