@font-face {
  font-family: "capitoApp";
  src: url($ca-font-path+"/capitoApp.eot?8t90ge");
  src: url($ca-font-path+"/capitoApp.eot?8t90ge#iefix")
      format("embedded-opentype"),
    url($ca-font-path+"/capitoApp.ttf?8t90ge") format("truetype"),
    url($ca-font-path+"/capitoApp.woff?8t90ge") format("woff"),
    url($ca-font-path+"/capitoApp.svg?8t90ge#capitoApp") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.cai {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "capitoApp" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cai-ll-a1-na:before {
  content: "\e900";
}

.cai-ll-a1:before {
  content: "\e901";
}

.cai-ll-a2-na:before {
  content: "\e902";
}

.cai-ll-a2:before {
  content: "\e903";
}

.cai-ll-b1-na:before {
  content: "\e904";
}

.cai-ll-b1:before {
  content: "\e905";
}

.cai-ll-original-na:before {
  content: "\e906";
}

.cai-ll-original:before {
  content: "\e907";
}
